// @flow

import React from 'react'
import 'swiper/swiper-bundle.css'
import type { Image } from 'types/'
import useIsMobile from '../hooks/useIsMobile'
import SliderHomeDesktop from './SliderHomeDesktop'
import SliderHomeMobile from './SliderHomeMobile'

type SlideItem = {
  id: string,
  image: Image,
  supertitle?: string,
  title: string,
  text?: string,
  ctaLabel?: string,
  ctaHref?: string,
}
type Props = {
  items: Array<SlideItem>,
  maskMobile: Image,
  maskDesktop: Image,
  maskEnd: Image,
}

const SliderHome = ({ items, maskMobile, maskDesktop, maskEnd }: Props) => {
const isMobile = useIsMobile()

if (isMobile) {
  return <SliderHomeMobile items={items} mask={maskMobile} />
}

  return (
     <SliderHomeDesktop items={items} mask={maskDesktop} maskEnd={maskEnd} />
  )
}

export default SliderHome
