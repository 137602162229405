// @flow

import React, { useState, useEffect } from 'react'
import type { Image } from 'types/'
import Swiper from 'react-id-swiper'
import 'swiper/swiper-bundle.css'
import SlideActivity from './SlideActivity'
import Svg from './Svg'

type SlideItem = {
  id: string,
  image: Image,
  supertitle: string,
  title: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
}
type Props = {
  items: Array<SlideItem>,
  mask: Image,
}

const SliderActivitiesDesktop = ({ items, mask }: Props) => {
  const [swiper, setSwiper] = useState(null)
  const [active, setActive] = useState(0)
  const [mouseOverLeft, setMouseOverLeft] = useState(false)
  const [mouseOverRight, setMouseOverRight] = useState(false)

  useEffect(() => {
  }, [active])

  const params: any = {
    loop: true,
    slidesPerView: 'auto',
    loopedSlides: items.length,
    centeredSlides: true,
    spaceBetween: 30,
    speed: 1000,
    watchSlidesVisibility: true,
    updateOnWindowResize: true,
    runCallbacksOnInit: true,
    onInit: (swiper) => {setSwiper(swiper)},
  }

  const prevItem = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setActive(swiper.realIndex)
    }
  }

  const nextItem = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setActive(swiper.realIndex)
    }
  }

  return (
  <div className="relative">
    <Swiper
      {...params}
      slideClass="swiper-slide-activity"
      containerClass="swiper-container-activities"
      slideActiveClass="swiper-slide-acivity-active"
      ref={node => {node ? setSwiper(node.swiper) : setSwiper(null)}}
    >
    {items.map((item, i) => <div key={i}>
      <SlideActivity
        mask={mask}
        image={item.image}
        supertitle={item.supertitle}
        title={item.title}
        text={item.text}
        ctaLabel={item.ctaLabel}
        ctaHref={item.ctaHref}
        isMobile={false}
      />
    </div>)}
    </Swiper>
    <div style={{ marginTop: -290 }} className="absolute z-10 w-full">
      <div className="flex justify-between mx-8">
        <div
        onClick={prevItem}
        onMouseOver={() => setMouseOverLeft(true)}
        onMouseLeave={() => setMouseOverLeft(false)}
        className="relative flex items-center justify-center"
        >
          <Svg name="oval-left" width={42} height={42} color={mouseOverLeft ? "gray" : "white"} />
          <div className="absolute">
            <Svg name="arrow-left" width={12} height={16} color={mouseOverLeft ? "white" : "gray"} />
          </div>
        </div>
        <div
        onClick={nextItem}
        onMouseOver={() => setMouseOverRight(true)}
        onMouseLeave={() => setMouseOverRight(false)}
        className="relative flex items-center justify-center"
        >
          <Svg name="oval-right" width={42} height={42} color={mouseOverRight ? "gray" : "white"} />
          <div className="absolute">
            <Svg name="arrow-right" width={9} height={16} color={mouseOverRight ? "white" : "gray"} />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SliderActivitiesDesktop
