// @flow

import React, { useState, useEffect } from 'react'
import type { Image } from 'types/'
import Swiper from 'react-id-swiper'
import 'swiper/swiper-bundle.css'
import SlideActivity from './SlideActivity'
import Svg from './Svg'

type SlideItem = {
  id: string,
  image: Image,
  supertitle: string,
  title: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
}
type Props = {
  items: Array<SlideItem>,
  mask: Image,
}

const SliderActivitiesMobile = ({ items, mask }: Props) => {
  const [swiper, setSwiper] = useState(null)
  const [active, setActive] = useState(0)

  useEffect(() => {
  }, [active])

  const params: any = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    loop: true,
    slidesPerView: 'auto',
    loopedSlides: items.length,
    spaceBetween: 0,
    speed: 1000,
    watchSlidesVisibility: true,
    updateOnWindowResize: true,
    runCallbacksOnInit: true,
    onInit: (swiper) => {setSwiper(swiper)},
  }

  const prevItem = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setActive(swiper.realIndex)
    }
  }

  const nextItem = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setActive(swiper.realIndex)
    }
  }

  return (
    <>
      <Swiper
        {...params}
        slideClass="swiper-slide-activity"
        containerClass="swiper-container-activities"
        slideActiveClass="swiper-slide-acivity-active"
        ref={node => {node ? setSwiper(node.swiper) : setSwiper(null)}}
      >
      {items.map((item, i) => <div key={i} className="pb-12">
        <SlideActivity
          mask={mask}
          image={item.image}
          supertitle={item.supertitle}
          title={item.title}
          text={item.text}
          ctaLabel={item.ctaLabel}
          ctaHref={item.ctaHref}
          isMobile={true}
        />
      </div>)}
      </Swiper>
      <div className="relative z-10 flex justify-between -mt-5">
          <div onClick={prevItem} className="px-4">
            <Svg name="arrow-left" width={12} height={20} color='gray' />
          </div>
          <div onClick={nextItem} className="px-4">
            <Svg name="arrow-right" width={12} height={20} color='gray' />
          </div>
      </div>
    </>
  )
}

export default SliderActivitiesMobile
