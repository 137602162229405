// @flow

import React, { useState, useEffect } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/swiper-bundle.css'
import Svg from './Svg'

type Props = {
  items: Array<Object>
}

const SliderTestimonialsDesktop = ({ items }: Props) => {
  const [swiper, setSwiper] = useState(null)
  const [active, setActive] = useState(1)
  const [mouseOverLeft, setMouseOverLeft] = useState(false)
  const [mouseOverRight, setMouseOverRight] = useState(false)

  useEffect(() => {
  }, [active])

  function handlePagination() {
    if (items.length > 3) {
      return {
        el: '.swiper-pagination',
        clickable: true
      }
    } else return {
      el: '.swiper-pagination hidden',
      clickable: true
    }
  }

  const params: any = {
    pagination: handlePagination(),
    slidesPerView: 3,
    centeredSlides: true,
    centeredSlidesBounds: true,
    speed: 1000,
    watchSlidesVisibility: true,
    updateOnWindowResize: true,
    runCallbacksOnInit: true,
    onInit: (swiper) => {setSwiper(swiper)},
  }

  const prevItem = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setActive(swiper.realIndex)
    }
  }

  const nextItem = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setActive(swiper.realIndex)
    }
  }

  return (
    <>
      <Swiper
        {...params}
        slideClass="swiper-slide-testimonial"
        containerClass="swiper-container-testimonials"
        slideActiveClass="swiper-slide-testimonial-active"
        ref={node => {node ? setSwiper(node.swiper) : setSwiper(null)}}
      >
      {items.map(item => <div
          key={item.id}
          className="md:px-3"
        >
          <div className="h-full px-12 py-10 border md:py-12 md:px-10 border-gray font-secondary">
            <p className="mb-8 leading-tight md:mb-8 font-primary text-bigger2 text-gray">{`“${item.text}”`}</p>
            <span className="font-secondary text-gray text-small md:text-normal">{item.author}</span>
          </div>
        </div>)}
      </Swiper>
      {items.length > 3 &&
      <div className="relative z-10 flex justify-between -mt-8 cursor-pointer">
          <div
          onClick={prevItem}
          onMouseOver={() => setMouseOverLeft(true)}
          onMouseLeave={() => setMouseOverLeft(false)}
          className="px-4"
          >
            <Svg name="arrow-left" width={12} height={20} color={mouseOverLeft ? "primary-brown" : "gray"} />
          </div>
          <div
          onClick={nextItem}
          onMouseOver={() => setMouseOverRight(true)}
          onMouseLeave={() => setMouseOverRight(false)}
          className="px-4"
          >
            <Svg name="arrow-right" width={12} height={20} color={mouseOverRight ? "primary-brown" : "gray"} />
          </div>
      </div>
      }
    </>
  )
}

export default SliderTestimonialsDesktop
