const sha1 = require("js-sha1")

const assets = []

// PROVISIONAL DEV
const GATSBY_imageSizes = "450,800,1200,2400"

const processNormalAsset = ({ normal, lazy }, extension) => {
  const normalName = `${sha1(normal)}.${extension}`
  const lazyName = `${sha1(lazy)}.${extension}`

  const foundNormal = assets.find((item) => item.name === normalName)
  if (!foundNormal) {
    assets.push({
      name: normalName,
      url: normal,
      extension,
    })
  }

  const foundLazy = assets.find((item) => item.name === lazyName)
  if (!foundLazy) {
    assets.push({
      name: lazyName,
      url: lazy,
      extension,
      lazy: true,
    })
  }
}

const processNormalFile = (file, extension) => {
  const fileName = `${sha1(file)}.${extension}`
  const found = assets.find((item) => item.name === fileName)
  if (!found) {
    assets.push({
      name: fileName,
      url: file,
      extension,
    })
  }
}

const processFile = ({ file }) => {
  if (file.length === 0) {
    return {
      file: "",
    }
  }
  const parts = file.split("extension=")
  const extension = parts[parts.length - 1]
  processNormalFile(file, extension)
  return {
    file: `/static/assets/media/${sha1(file)}.${extension}`,
  }
}

const processImage = ({ normal, lazy }) => {
  const parts = normal.split("extension=")
  const extension = parts[parts.length - 1]
  if (!normal || !lazy) {
    return {
      webp: false,
      extension: "",
      dir: "",
      name: {
        normal: "",
        lazy: "",
      },
      sizes: [],
    }
  }
  processNormalAsset({ normal, lazy }, extension)
  const isResizable = ["png", "jpg"].indexOf(extension) > -1
  return {
    webp: isResizable,
    extension: extension,
    dir: "", //aqui puede que vaya una /
    name: {
      normal: normal.replace(":80", ""),
      lazy: lazy.replace(":80", ""),
    },
    // sizes: process.env.GATSBY_imageSizes.split(','),
    sizes: GATSBY_imageSizes.split(","),
  }
}

const processModule = (item) => {
  if (typeof item.props !== "undefined" && item.props) {
    Object.keys(item.props).forEach((key) => {
      if (
        typeof item.props[key] !== "undefined" &&
        item.props[key] &&
        typeof item.props[key] === "object"
      ) {
        const itemKeys = Object.keys(item.props[key])
        if (
          itemKeys.length === 2 &&
          itemKeys.includes("normal") &&
          itemKeys.includes("lazy")
        ) {
          const value = processImage(item.props[key])
          item.props[key] = value
        } else if (itemKeys.length === 1 && itemKeys.includes("file")) {
          const value = processFile(item.props[key])
          item.props[key] = value
        } else if (item.props[key].image) {
          const value = processImage(item.props[key].image)
          item.props[key].image = value
        } else {
          if (Array.isArray(item.props[key])) {
            item.props[key] = processStructure(item.props[key])
          }
        }
      }
    })
  } else {
    if (item && typeof item !== "undefined") {
      Object.keys(item).forEach((key) => {
        if (
          typeof item[key] !== "undefined" &&
          item[key] &&
          typeof item[key] === "object"
        ) {
          const itemKeys = Object.keys(item[key])
          if (
            itemKeys.length === 2 &&
            itemKeys.includes("normal") &&
            itemKeys.includes("lazy")
          ) {
            const value = processImage(item[key])
            item[key] = value
          } else if (itemKeys.length === 1 && itemKeys.includes("file")) {
            const value = processFile(item[key])
            item[key] = value
          } else {
            if (Array.isArray(item[key])) {
              item[key] = processStructure(item[key])
            }
          }
        }
      })
    }
  }
  return item
}

const processStructure = (rawData) => {
  const data = []
  rawData.forEach((item) => {
    const parsedItem = processModule(item)
    if (typeof parsedItem.structure !== "undefined") {
      try {
        parsedItem.structure = processStructure(parsedItem.structure)
      } catch (err) {
        console.log(err)
      }
    }
    data.push(parsedItem)
  })
  return data
}

const processAll = async (structureRaw) => {
  return await processStructure(structureRaw)
}

const getAssets = () => {
  return assets
}

exports.getAssets = getAssets
exports.processStructure = processStructure
exports.assets = assets
exports.processAll = processAll
